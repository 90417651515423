<template>
  <div class="game-container">
    <FindStructureByPicGame
      :asideElementInfo="asideElementInfo"
      :questionInfoList="questionInfoList"
      :totalStars="totalStars"
      :isNumberGame="false"
    />
  </div>
</template>

<script>
import FindStructureByPicGame from "@/components/Course/GamePage/FindStructureByPicGame";
export default {
  data() {
    return {
      totalStars: 5,
      questionInfoList: [
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/n/ni3.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-1-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/w/wo3.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-2-mao-2.svg"),
          sound: require("@/assets/audio/a-zh/n/nihao.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-3-mei-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-1.svg"),
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-4-ta-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        {
          id: 2,
          bgImg: "",
          bgImg2: require("@/assets/img/16-GAMES//G74-structure-puzzle/hanzi-5-na-2.svg"),
          sound: require("@/assets/audio/a-zh/L/laoshi.mp3"),
        },
        
      ],
      asideElementInfo: {
        leftList: [
          {
            id: 1,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/1-shangxia.svg"),
          },
          {
            id: 2,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/2-zuoyou.svg"),
          },
          {
            id: 3,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/3-quanbao.svg"),
          },
          {
            id: 4,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/4-duti.svg"),
          },
         
        ],
        rightList: [
          
          {
            id: 5,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/5-zuoshang.svg"),
          },
          {
            id: 6,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/6-zuoxia.svg"),
          },
          {
            id: 7,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/7-zuosan.svg"),
          },
         {
            id: 8,
            image:require("@/assets/img/16-GAMES/G74-structure-puzzle/8-xiasan.svg"),
          },
        ],
      },
    };
  },
  components: {
    FindStructureByPicGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
