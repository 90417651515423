var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("ChooseImgDog", {
        attrs: {
          allImgList: _vm.allImgList,
          buttonList: _vm.buttonList,
          answerList: _vm.answerList,
          mailboxList: _vm.mailboxList,
          dragNum: _vm.dragNum,
          durationArr: _vm.durationArr,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }