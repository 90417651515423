var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-one" },
    [
      _c("HanziCardGame", {
        attrs: {
          backgroundImage: _vm.bgImg,
          wordObj: _vm.wordDetail,
          maskImg: _vm.maskImg,
          titleInfo: _vm.titleInfo,
          changeBgImg: _vm.changeBgImg,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }