<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="false" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "复习巩固",
          isReview: true,
          contentList: [
            [
              {
              pinyin: "cí huì",
              hanzi: "词汇",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
              pinyin: "qiú",
              hanzi: "球",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "ne",
              hanzi: "呢",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "wán",
              hanzi: "玩",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "diàn shì",
              hanzi: "电视",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
              // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
            ],
            [
             {
                pinyin: "jù xíng",
                hanzi: "句型",
                symbol: "",
                group: 1,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
               {
                pinyin: "\xa0",
                hanzi: "......",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
               {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
                pinyin: "\xa0",
                hanzi: "......",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "ne",
                hanzi: "呢？",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 3,
              },
              
               {
                pinyin: "jìn xíng tí wèn",
                hanzi: "进行提问",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
            ],
          ],
        },
        {
          title: "我知道",
          isReview: false,
          content: [
             {
              pinyin: "\xa0",
              hanzi: "1",
              symbol: "",
              group: 1,
              index: 0,
              symbolType: 0,
            },
            {
                pinyin: "",
                hanzi: "",
                symbol: ".",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
            {
              pinyin: "liǎo jiě",
              hanzi: "了解",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "hàn zì de",
              hanzi: "汉字的",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "jī běn jié gòu",
              hanzi: "基本结构",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
             {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
            // {
            //   pinyin: "shu",
            //   hanzi: "",
            //   symbol: "",
            //   group: 1,
            //   index: 2,
            //   isHavePinyin: false,
            //   imgUrl: require("@/assets/img/11-Syllabus/lesson-02-shu.svg"),
            //   symbolType: 8,
            // },
            {
              pinyin: "shàng xià jié gòu",
              hanzi: "上下结构",
              symbol: "",
              group: 1,
              index: 3,
              symbolType: 0,
            },
            
             {
              pinyin: "\xa0",
              hanzi: "2",
              symbol: "",
              group: 1,
              index: 0,
              symbolType: 0,
            },
            {
                pinyin: "",
                hanzi: "",
                symbol: ".",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
            {
              pinyin: "yǒu qù de",
              hanzi: "有趣的",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "hàn zì",
              hanzi: "汉字",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
             {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 1,
                index: 1,
                symbolType: 2,
              },
            
            // {
            //   pinyin: "shu",
            //   hanzi: "",
            //   symbol: "",
            //   group: 1,
            //   index: 2,
            //   isHavePinyin: false,
            //   imgUrl: require("@/assets/img/11-Syllabus/lesson-02-shu.svg"),
            //   symbolType: 8,
            // },
            {
              pinyin: "wán",
              hanzi: "“玩”",
              symbol: "",
              group: 1,
              index: 3,
              symbolType: 0,
            },
          ],
        },
        {
          title: "我会写",
          isReview: false,
          content: [
            {
              pinyin: "ànzhào",
              hanzi: "能够",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },

            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "bǐshùn",
              hanzi: "笔顺",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "zhèngquè",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "diàn",
              hanzi: "“电”",
              symbol: "",
              group: 2,
              index: 5,
              isHavePinyin: true,
              // imgUrl: require("@/assets/img/11-Syllabus/lesson-02-ni.svg"),
              symbolType: 0,
            },
            {
              pinyin: "nǐ",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 6,
              isHavePinyin: true,
              // imgUrl: require("@/assets/img/11-Syllabus/lesson-02-ni.svg"),
              symbolType: 0,
            },
            {
              pinyin: "ne",
              hanzi: "“呢”",
              symbol: "",
              group: 2,
              index: 7,
              isHavePinyin: true,
              // imgUrl: require("@/assets/img/11-Syllabus/lesson-02-ni.svg"),
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>