<template>
  <div class="recognition-page-one">
    <HanziCardGame :backgroundImage="bgImg" :wordObj="wordDetail" :maskImg="maskImg" :titleInfo="titleInfo" :changeBgImg="changeBgImg"/>
  </div>
</template>

<script>
import HanziCardGame from "@/components/Course/CoursePage/HanziCardGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-image-1.svg"),
      changeBgImg: require("@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-image-2.svg"),
      wordDetail: {
        pinName: "wán",
        chineseName: "玩",
        pinyin: "wánqiú wánju ",
        hanzi:"玩球、玩具",
        songUrl: require("@/assets/audio/a-zh/n/nihao.mp3"),
      },
      maskImg:require("@/assets/img/16-GAMES/G67-hanzi-card/lesson-02-zuo-qingzuo.svg"),
      titleInfo:{
        pinyin:"Yǒuqù de Hànzì",
        hanzi:"有趣的汉字",
        bgColor:'redColor'
      }
    };
  },
  components: {
    HanziCardGame,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>