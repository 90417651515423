var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recognition-page-Two" },
    [
      _c("RecognitionImgPage", {
        attrs: {
          backgroundImage: _vm.backgroundImage,
          wordObj: _vm.wordObj,
          rightImg: _vm.rightImg,
          changeBgImg: _vm.changeBgImg,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }