<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Wǒ xǐhuān wánqiú, nǐ ne (xià)",
        hanzi: "我喜欢玩球，你呢？（下）",
        english: "I Like to Play Ball Games, How About You? (II)",
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>