<template>
  <div class="game-container">
    <PhoneGame
      :answerList="answerList"
    />
  </div>
</template>

<script>
import PhoneGame from "@/components/Course/GamePage/PhoneGame";
export default {
  data() {
    return {
      answerList: [
        {
          answerInfo:"三个球",
          answerPin:"sān ge qiú ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/qingzuo.svg`),
          allImgList: [
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"sān",
              hanzi:"三",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"diàn",
              hanzi:"电",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"qiú",
              hanzi:"球 ",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"me",
              hanzi:"么",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"shén",
              hanzi:"什",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"shì",
              hanzi:"视",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"ge",
              hanzi:"个",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 8,
              pinyin: "kàn",
              hanzi:"看"
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 9,
              pinyin: "wán",
              hanzi:"玩"
            },
          ],
        },
        {
          answerInfo:"看电视",
          answerPin:"kàn diànshì ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/keyi.svg`),
          allImgList: [
          {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"sān",
              hanzi:"三",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"diàn",
              hanzi:"电",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"qiú",
              hanzi:"球 ",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"me",
              hanzi:"么",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"shén",
              hanzi:"什",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"shì",
              hanzi:"视",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"ge",
              hanzi:"个",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 8,
              pinyin: "kàn",
              hanzi:"看"
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 9,
              pinyin: "wán",
              hanzi:"玩"
            },
          ],
        },
        {
          answerInfo:"玩什么",
          answerPin:"wán shénme ",
          audioSrc: require("@/assets/audio/a-zh/a/a1.mp3"),
          rightImg:require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/bukeqi.svg`),
          allImgList: [
          {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke3-done.svg`),
              id:1,
              pinyin:"sān",
              hanzi:"三",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-bu-done.svg`),
              id:2,
              pinyin:"diàn",
              hanzi:"电",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-yi-done.svg`),
              id:3,
              pinyin:"qiú",
              hanzi:"球 ",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qing-done.svg`),
              id:4,
              pinyin:"me",
              hanzi:"么",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-ke4-done.svg`),
              id:5,
              pinyin:"shén",
              hanzi:"什",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-qi-done.svg`),
              id:6,
              pinyin:"shì",
              hanzi:"视",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-yellow.svg`),
              bgImgDone: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-zuo-done.svg`),
              id:7,
              pinyin:"ge",
              hanzi:"个",
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 8,
              pinyin: "kàn",
              hanzi:"看"
            },
            {
              bgImg: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty.svg`),
              bgImgYellow: require(`@/assets/img/16-GAMES/G64-typewriter/l2-lesson-02/button-empty-yellow.svg`),
              id: 9,
              pinyin: "wán",
              hanzi:"玩"
            },
          ],
        },
      ],
    };
  },
 
  components: {
    PhoneGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>